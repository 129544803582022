.bg-primary {
  background-color: $primary !important;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($primary, 10%) !important;
    border-color: darken($primary, 10%) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($primary, 0.5);
  }
}

.btn-secondary {
  background-color: $secondary;
  border-color: $secondary;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($secondary, 10%) !important;
    border-color: darken($secondary, 10%) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($secondary, 0.5);
  }
}

