header.masthead {
  position: relative;
  overflow: hidden;
  padding-top: calc(7rem + 72px);
  padding-bottom: 7rem;
  background: linear-gradient(0deg, $secondary 0%, $primary 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  .masthead-content {
    z-index: 1;
    position: relative;
    .masthead-heading {
      font-size: 4rem;
    }
    .masthead-subheading {
      font-size: 2rem;
    }
  }
  .bg-circle {
    z-index: 0;
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(0deg, $primary 0%, $secondary 100%);
  }
  .bg-circle-1 {
    height: 90rem;
    width: 90rem;
    bottom: -55rem;
    left: -55rem;
  }
  .bg-circle-2 {
    height: 50rem;
    width: 50rem;
    top: -25rem;
    right: -25rem;
  }
  .bg-circle-3 {
    height: 20rem;
    width: 20rem;
    bottom: -10rem;
    right: 5%;
  }
  .bg-circle-4 {
    height: 30rem;
    width: 30rem;
    top: -5rem;
    right: 35%;
  }
}

@media (min-width: 992px) {
  header.masthead {
    padding-top: calc(10rem + 55px);
    padding-bottom: 10rem;
    .masthead-content {
      .masthead-heading {
        font-size: 6rem;
      }
      .masthead-subheading {
        font-size: 4rem;
      }
    }
  }
}

header.masthead-mini {
  position: relative;
  overflow: hidden;
  padding-top: calc(7rem + 24px);
  background: linear-gradient(0deg, $secondary 0%, $primary 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  .masthead-content {
    z-index: 1;
    position: relative;
    .masthead-heading {
      font-size: 4rem;
    }
    .masthead-subheading {
      font-size: 2rem;
    }
  }
  .bg-circle {
    z-index: 0;
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(0deg, $primary 0%, $secondary 100%);
  }
  .bg-circle-1 {
    height: 90rem;
    width: 90rem;
    bottom: -55rem;
    left: -55rem;
  }
  .bg-circle-2 {
    height: 50rem;
    width: 50rem;
    top: -25rem;
    right: -25rem;
  }
  .bg-circle-3 {
    height: 20rem;
    width: 20rem;
    bottom: -10rem;
    right: 5%;
  }
  .bg-circle-4 {
    height: 30rem;
    width: 30rem;
    top: -5rem;
    right: 35%;
  }
}
